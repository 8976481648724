import React from "react"
import { navigate } from "gatsby"
import { AuthProvider } from "react-use-auth"

export const wrapRootElement = ({ element }) => {
    return (
      <AuthProvider
        navigate={navigate}
        auth0_domain="applydigital.auth0.com"
        auth0_client_id="oOCgxOepbZwQxkBL14G8gkipt1LrHmEh"
        auth0_params={{redirectUri: `${process.env.ORIGIN}/auth0_callback/`}}
      >
        {element}
      </AuthProvider>
  )
}